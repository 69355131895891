<template>
    <div class="pay">
        <div class="header">
            {{ payType == 1 ? '微信' : '支付宝' }} 支付收银台
        </div>
        <div class="body">
            <div class="left">
                <p style="color: red;">
                    扫码支付后，请等待5秒钟，页面将会跳转到支付成功页面进行安装，期间请勿关闭当前页面，
                    <b style="font-size: 20px;">遇到任何问题请联系客服， 安装失败，立马退款，一次购买，终生售后，请放心购买</b>
                </p>
                <div class="sec">
                    <div class="line1">
                        <span>限时抢购：</span>
                        <p>{{ min }}分</p>
                        <p>{{ sec }}秒</p>
                    </div>
                    <div class="line2">
                        <span>优惠价：{{ money }}元</span>
                    </div>
                </div>
                <div class="qrcode">
                    <div class="row1">
                        <img src="../../assets/image/zfb.png" alt="" v-if="payType == 2">
                        <img src="../../assets/image/wx.png" alt="" v-if="payType == 1">
                    </div>
                    <div class="row2">
                        NO.ADOBE23121582H0B2NFK12345
                    </div>
                    <div class="row3">
                        ￥{{ money }}
                    </div>
                    <div class="row4">
                        <img src="../../assets/image/二维码.png" alt="">
                    </div>
                    <div class="row5">
                        <p>请使用 {{ payType == 1 ? '微信' : '支付宝' }} 扫一扫</p>
                        <p>扫描二维码支付</p>
                    </div>
                </div>
            </div>
            <div class="right">
                <img src="../../assets/image/scan.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            payType: '',
            min: 2,
            sec: 0,
            money: 0,
            interval: '',
            totalSec: 120,
            id: ''
        }
    },
    methods: {
        // 更新倒计时的函数
        updateCountdown() {
            // 将剩余时间转换为分钟和秒
            var minutes = Math.floor(this.totalSec / 60);
            var seconds = this.totalSec % 60;

            // 格式化时间，确保始终显示两位数
            var formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
            var formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

            this.min = formattedMinutes;
            this.sec = formattedSeconds;

            // 检查倒计时是否已结束
            if (this.totalSec <= 0) {
                clearInterval(intervalId); // 清除定时器
            } else {
                this.totalSec--; // 减少剩余时间
            }
        }
    },
    mounted() {
        // console.log(this.$route);
        this.payType = this.$route.query.payType;
        // console.log(this.payType,22222);
        let obj = localStorage.getItem('payOption')?JSON.parse(localStorage.getItem('payOption')):{}
        if(obj){
            this.money = obj.price;
        }

        // 每一秒更新倒计时
        this.interval = setInterval(this.updateCountdown, 1000);

    }
}
</script>

<style scoped lang="less">
.pay {
    width: 100%;
    height: 100vh;
    background-color: #f4f4f4;
}

.header {
    height: 75px;
    line-height: 75px;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    border-bottom: 2px solid #eee;
    background: #fff;
}

.body {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 85px 20px 20px 50px;

    .left {
        width: 40%;

        .sec {
            border: 2px solid #808A87;
            border-radius: 10px;
            margin: 10px 0;
            padding: 5px;
            color: #c71c60;
            font-family: Arial, Helvetica, sans-serif;

            .line1 {
                display: flex;
                align-items: center;

                span {
                    font-size: 25px;
                    line-height: 38px;
                }

                p {
                    background: #C71C60;
                    color: #fff;
                    line-height: 38px;
                    font-size: 25px;
                    font-family: Arial;
                    padding: 0 10px;
                    margin-right: 10px;
                    border-radius: 5px;
                    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
                }
            }

            .line2 {
                display: flex;
                align-items: center;
                margin: 10px 0;

                span {
                    font-size: 20px;
                    margin-right: 10px;
                }

                del {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .qrcode {
            border-radius: 10px;
            background: #fff;
            text-align: center;
            padding-top: 25px;
            color: #555;
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .05);
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;

            .row1 {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .row2 {
                margin: 10px 0;
            }

            .row3 {
                font-size: 22px;
                font-weight: bold;
                margin: 0px auto;
                width: 100%;
            }

            .row4 {
                background-color: #ccc;
                margin: 10px 0;

                img {
                    width: 210px;
                    height: 210px;
                }
            }

            .row5 {
                border-radius: 0 0 10px 10px;
                width: 100%;
                background: #32343d;
                color: #f2f2f2;
                padding: 15px 0px;
                text-align: center;
                font-size: 14px;
            }
        }
    }

    .right {
        width: 60%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 310px;
            height: 380px;
        }
    }
}
</style>